import React from 'react';
import Helmet from 'react-helmet';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactResizeDetector from 'react-resize-detector';

import Description from './Description';
import ProductEntry from './ProductEntry';

import './ProductDetail.module.css';
import ContactForm from './ContactForm';

ProductDetail.propTypes = {};
ProductDetail.defaultProps = {};

function ProductDetail({ data }) {
  const { manufacturer, productGroup, products } = data;

  const { name, description } = productGroup;

  let logoToUse = null;
  if (manufacturer.logopng) {
    logoToUse = getImage(manufacturer.logopng);
  }
  if (!logoToUse && manufacturer.logojpg) {
    logoToUse = getImage(manufacturer.logojpg);
  }

  const filteredProducts = products.filter((product) => {
    return product != null;
  });

  const determineNumberOfColumns = (width) => {
    if (width > 1100) return 4;
    if (width > 850) return 3;
    if (width > 600) return 2;
    return 1;
  };

  return (
    <article>
      <div>
        <Helmet>
          <title>{name}</title>
          <meta name="description" content={description} />
        </Helmet>
        <div className="widewrap" style={{ textAlign: 'center' }}>
          <GatsbyImage alt="" image={getImage(productGroup.image)} />
        </div>
        <div className="defaultWidth">
          <Description node={productGroup} logo={logoToUse} />
          <ReactResizeDetector handleWidth>
            {(width) => {
              const numberOfColumns = determineNumberOfColumns(width.width);
              const contactFormWidth =
                filteredProducts.length % numberOfColumns === 0
                  ? numberOfColumns
                  : numberOfColumns - (filteredProducts.length % numberOfColumns);

              return (
                <div style={{ margin: '0 -4px -8px -4px' }}>
                  {products.map((product) => (
                    <ProductEntry product={product} key={product.id} cols={numberOfColumns} />
                  ))}
                  <ContactForm cols={numberOfColumns} width={contactFormWidth} />
                </div>
              );
            }}
          </ReactResizeDetector>
        </div>
        {/*

          <ReactResizeDetector handleWidth>
            {(width) => {
              const numberOfColumns = determineNumberOfColumns(width.width);
              console.log('numberOfColumns', numberOfColumns);
              const contactFormWidth =
                filteredProducts.length % numberOfColumns === 0
                  ? numberOfColumns
                  : numberOfColumns - (filteredProducts.length % numberOfColumns);
              return (
                <div style={{ margin: '0 -4px -8px -4px' }}>
                  {products.map((product) => (
                    <ProductEntry product={product} key={product.id} cols={numberOfColumns} />
                  ))}
                  <ContactForm cols={numberOfColumns} width={contactFormWidth} />
                </div>
              );
            }}
          </ReactResizeDetector>
        </div> */}
      </div>
    </article>
  );
}

export default ProductDetail;
